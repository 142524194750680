/* @import url("https://fonts.googleapis.com/css2?family=Barlow:wght@500&display=swap"); */

@font-face {
  font-family: "en";
  src: url("assets/fonts/NHaasGroteskDSPro-45Lt.woff2") format("woff2"),
    url("assets/fonts/NHaasGroteskDSPro-45Lt.woff") format("woff"),
    url("assets/fonts/NHaasGroteskDSPro-45Lt.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "en";
  src: url("assets/fonts/NHaasGroteskDSPro-55Rg.woff2") format("woff2"),
    url("assets/fonts/NHaasGroteskDSPro-55Rg.woff") format("woff"),
    url("assets/fonts/NHaasGroteskDSPro-55Rg.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "kor";
  src: url("assets/fonts/SourceHanSansK-Regular.woff") format("woff"),
    url("assets/fonts/SourceHanSansK-Regular.otf") format("otf");
  font-display: block;
}

* {
  box-sizing: border-box;
}

*::selection {
  background-color: rgba(0, 255, 0, 0.3);
}

html {
  font-size: 16px;
  overflow-y: scroll;
  background: #fff;
}

@media (max-width: 1024px) {
  html {
    font-size: 8px;
  }
}

.lazy-load-image-background {
  width: 100%;
}

body {
  font-family: "en";
  letter-spacing: 0.04em;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  margin: 0 auto;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

a:visited {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1;
  margin: 0;
  padding: 0;
  font-weight: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  cursor: pointer;
  -webkit-appearance: none;
  padding: 0;
  margin: 0;
  border: 0;
  background: none;
}

input,
button {
  outline: none;
  font-family: "en";
}

img {
  width: 100%;
  vertical-align: top;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.hover-green-light {
  position: relative;
}

.hover-green-light::after {
  content: "";
  position: absolute;
  height: 2px;
  left: 0%;
  width: 0%;
  bottom: 0;
  background: #00ff00;
  transition: all 0.33s ease;
}

.hover-green-light.default::after {
  content: "";
  width: 100%;
  background-color: #00ff00;
}

.hover-green-light:hover::after {
  content: "";
  width: 100%;
  background-color: #00ff00;
}

.hover-green-light.b-1::after {
  bottom: -1px;
}

.hover-green-light.b-2::after {
  bottom: -2px;
}

.hover-green-light.b-3::after {
  bottom: -3px;
}

input[type="text"] {
  border-radius: 0;
  margin: 0;
}
